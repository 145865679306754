:root {
  --highlight-color: #00c266;
}

/*scroll bar */
body::-webkit-scrollbar {
  width: 0px;
}
body::-webkit-scrollbar-track {
  background: var(--color-3);
}
body::-webkit-scrollbar-thumb {
  background-image: linear-gradient(
    to right bottom,
    var(--color-1),
    var(--color-6) 50%,
    var(--color-7)
  );
  border-radius: 20px;
}
body::-webkit-scrollbar-thumb:hover {
  border-radius: 0px;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  position: relative;
}

html {
  color: #fff;
  background-color: #000;
  width: 100vw;
  overflow-x: hidden;
}

body {
  font: 100%/1.5 "Gill Sans", -apple-system, BlinkMacSystemFont, sans-serif;
}

a {
  color: inherit;
  text-decoration: none;
  border-bottom: 2px solid rgba(255, 255, 255, 0.5);
}

a:hover {
  color: var(--highlight-color);
  border-bottom-color: inherit;
}

p {
  margin-bottom: 0.75rem;
}

a:hover {
  color: var(--highlight-color);
}

.pad-s {
  padding: 0.75rem;
}
